import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import jwtDecode from 'jwt-decode'
import type { JwtPayload } from 'jwt-decode'

import { OAuth } from '@aws-production/auth-user'

import config from '@/app.config'
import { ErrorCode } from '@/api/constants'


export type UserInfo = {
  id: string
  email: string
  username: string
}

export type Session = {
  token: string
  user: UserInfo
}

export type LoginResult = ErrorCode | null

export type JwtPayloadGithub = JwtPayload & {
  sub: string
  eml: string
  usn: string
}


const authUri = config.VUE_APP_OAUTH_URI
const redirectUri = config.VUE_APP_OAUTH_REDIRECT_URI
const googleAppClientId = config.VUE_APP_OAUTH_GOOGLE_APP_CLIENT_ID

const sessionKey = 'translucence-login'

/* Initialize OAuth client */
const oauth = new OAuth(
  {
    authUri,
    redirectUri,
    refreshUri: 'http://localhost:5173/login',
  },
  {
    google: {
      client_id: googleAppClientId,
      scope: 'openid profile email',
    },
  },
)

const emptySession: Session = {
  token: '',
  user: {
    id: '',
    email: '',
    username: '',
  },
}

/**
 * Helper function to check if token has expired.
 */
function isTokenExpired(token: string) {
  const parsedToken = jwtDecode<JwtPayloadGithub>(token)
  const { exp } = parsedToken

  if (!exp) return false

  return new Date() > new Date(exp * 1000)
}

export const useAuth = defineStore('auth', {
  state: () => ({
    session: useLocalStorage(sessionKey, emptySession, { mergeDefaults: true }),
  }),
  getters: {
    /**
     * Check whether token exists and has not expired.
     */
    isAuthenticated: (state) => {
      if (!state.session.token) return false

      if (isTokenExpired(state.session.token)) {
        console.info('Auth token has expired.')

        state.session.token = ''

        return false
      }

      return true
    },
  },
  actions: {
    /**
     * Log in by authenticating via OAuth.
     *
     * @return `error` code on failure
     */
    async login(): Promise<ErrorCode | undefined> {
      if (this.isAuthenticated) return

      let idToken: string | undefined

      try {
        idToken = await oauth.login()
      } catch (error) {
        console.error(`Login failed from unrecognized error: ${error}`)

        return ErrorCode.UNRECOGNIZED
      }

      if (!idToken) return ErrorCode.NOT_AUTHENTICATED

      const parsedIdToken = jwtDecode<JwtPayloadGithub>(idToken)

      this.session.user = {
        id: parsedIdToken.sub,
        email: parsedIdToken.eml,
        username: parsedIdToken.eml.split('@')[0], // Google does not have usernames
      }
      this.session.token = idToken
    },

    /**
     * Redirect to OAuth provider (currently only Github supported).
     */
    redirect() {
      oauth.redirect('google')
    },

    /**
     * Log out by purging auth sessions from local storage.
     */
    logout() {
      oauth.logout()
      this.session = emptySession
    },
  },
})

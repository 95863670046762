/**
 * Allows for injection of environment variables at runtime (Kubernetes).
 * Taken from: https://github.com/tuuturu/pager-frontend
 */
export default (() => {
  const { env } = window as any

  const config = {
    VUE_APP_VERSION: process.env.VUE_APP_VERSION,
    VUE_APP_OAUTH_URI: process.env.VUE_APP_OAUTH_URI,
    VUE_APP_OAUTH_REDIRECT_URI: process.env.VUE_APP_OAUTH_REDIRECT_URI,
    VUE_APP_OAUTH_GOOGLE_APP_CLIENT_ID: process.env.VUE_APP_OAUTH_GOOGLE_APP_CLIENT_ID,
    VUE_APP_SERVER_BASE_URL: process.env.VUE_APP_SERVER_BASE_URL,
    VUE_APP_OP_MAX_CONCURRENT: process.env.VUE_APP_OP_MAX_CONCURRENT ?? 8,
    ...env,
  }

  return config
})()

import { createRouter, createWebHistory } from 'vue-router'

import { useAuth } from '@/store/auth'
import Login from '@/views/LoginPage.vue'
import RunnerClientRoutes from '@/apps/runner-client/routes'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/app/runner-client/',
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login,
        },
        {
          path: '/app',
          name: 'app',
          redirect: '/app/runner-client/',
          component: () => import('@/layouts/TheLoggedInLayout.vue'),
          meta: { requiresAuth: true },
          children: [
            ...RunnerClientRoutes,
          ],
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: '/app',
    },
  ],
})

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !useAuth().isAuthenticated) {
    return { name: 'login' }
  }
})

export default router

export default [
  {
    path: '/runner-client',
    name: 'runner-client',
    redirect: '/app/runner-client/',
    children: [
      {
        path: '/app/runner-client/',
        name: 'runner-client-root',
        component: () => import('@/apps/runner-client/views/MainPage.vue'),
      },
    ],
  },
]
